import { hexToRGB } from '../utils/Tools';
import styled from 'styled-components/macro';

export const Section = styled.div<SectionProps>`
  --background: ${p => p.theme[p.bg].background};
  --color: ${p => p.theme[p.bg].text};
  --hoverColor: ${p => p.theme[p.bg].hoverColor};

  --backgroundRGB: ${p => hexToRGB(p.theme[p.bg].background).join(',')};
  --colorRGB: ${p => hexToRGB(p.theme[p.bg].text).join(',')};
  --hoverColorRGB: ${p => hexToRGB(p.theme[p.bg].hoverColor).join(',')};
  
  background: var(--background);
  color: var(--color);
  padding: ${p => p.gutter !== null && typeof p.gutter !== 'undefined' ? p.gutter : 0} 0;
`;

interface SectionProps {
  bg: ThemeBG;
  gutter?: string;
}