import { Link } from 'gatsby';
import styled, { css } from 'styled-components/macro';
import { desktopHover, pxtorem } from '../../utils/Tools';

const DefaultCtaStyle = css`
  --padding: 15px 26px;
  transition: .25s;
  transition-property: border, background, color;
  display: inline-block;
  background: transparent;
  border: 1px solid var(--color);
  padding: var(--padding);
  border-radius: 114px;
  font: 300 ${pxtorem(20)}/120% var(--secondaryFont);
  text-decoration: none;
  color: var(--color);
  text-transform: uppercase;
  min-width: 228px;
  text-align: center;
  
  /* &.cta--wide { --padding: 8px 50px; }
  &.cta--small { --padding: 8px 23px; }
  &.cta--normal { --padding: 8px 20px; } */

  ${desktopHover(`
    border-color: var(--hoverColor);
    background: var(--hoverColor);
    color: var(--charcoal);

    .theme-solitude &, .theme-cloud &, .theme-cream &, .theme-salmon &, .theme-seafoam & {
      color: var(--pebble);
    }
  `)}

  @media (max-width: 500px) {
    width: 100%;
  }
`;

export const StyledA = styled.a<CtaProps>`
  ${DefaultCtaStyle}
`;

export const StyledLink = styled(Link)`
  ${DefaultCtaStyle}
`;

export const StyledBtn = styled.button<CtaProps>`
  background: transparent;
  border: none;
  /* cursor: pointer; */
  ${DefaultCtaStyle}
`;

interface CtaProps {
  size?: 'small' | 'normal' | 'wide';
}